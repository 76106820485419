/* stylelint-disable custom-property-empty-line-before */
:root {
  /* colors */
  --primary-oqio-red: #ff5300;
  --primary-oqio-green: #00ce82;
  --primary-oqio-blue: #627fff;
  --primary-oqio-yellow: #ffdc00;

  --secondary-mustard: #f4c569;
  --secondary-tangerine: #ff9d6d;
  --secondary-malibu: #6dc2ff;
  --secondary-perfume: #d49fe6;
  --secondary-geraldine: #ff868d;
  --secondary-woodruff: #94dda9;
  --secondary-whisper: #f5f6fa;

  --greyscale-white: #fff;
  --greyscale-porcelain: #f7f7f7;
  --greyscale-dark-white: #eee;
  --greyscale-light-grey: #d9d9d9;
  --greyscale-concrete: #b3b3b3;
  --greyscale-shade: #808080;
  --greyscale-blackish: #111;

  --primary-oqio-red-hover: #ff7b3b;
  --primary-oqio-green-hover: #2fd699;
  --primary-oqio-blue-hover: #8097ff;
  --primary-oqio-yellow-hover: #ffe333;
  
  --primary-oqio-blue-hover-transparent: rgb(98 127 255 / 10%);

  --damage-type-construction: #d5dd75;
  --damage-type-fire: var(--secondary-geraldine);
  --damage-type-water: var(--secondary-malibu);
  --damage-type-storm: var(--secondary-perfume);
  --damage-type-malicious: var(--secondary-mustard);
  --damage-type-glass: var(--secondary-woodruff);
  --damage-type-house: var(--secondary-tangerine);
  --damage-type-liability-construction: #9a90c1;
  --damage-type-liability-facility: #879fcc;
  --damage-type-liability-property: #9193d7;
  --damage-type-liability-water: #809bdf;
  --damage-type-other: var(--greyscale-concrete);

  /* font family */
  --font-family-regular: 'Work Sans', sans-serif;

  /* font-sizes */
  --font-size-small: 12px;
  --font-size-medium: 14px;
  --font-size-regular: 16px;
  --font-size-heading-5: 12px;
  --font-size-heading-4: 16px;
  --font-size-heading-3: 20px;
  --font-size-heading-2: 26px;
  --font-size-heading-1: 32px;

  /* font-weight */
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;

  /* font line height */
  --font-line-height-small: 16px;
  --font-line-height-regular: 20px;
  --font-line-height-medium: 26px;
  --font-line-height-large: 30px;
  --font-line-height-extra-large: 36px;

  /* font-letter-spacing */
  --font-letter-spacing-small: -0.5px;
  --font-letter-spacing-regular: normal;

  /* border-radius */
  --border-radius-small: 6px;
  --border-radius-regular: 8px;
  --border-radius-medium: 10px;
  --border-radius-large: 30px;
  --border-radius-extra-large: 100px;
  --border-radius-circle: 50%;

  /* box shadow */
  --box-shadow-oqio-red: 0 0 0 1px var(--primary-oqio-red);
  --box-shadow-oqio-blue:  0 0 0 1px var(--primary-oqio-blue);
  --box-shadow-drawer: 0 15px 20px 0 rgb(17 17 17 / 20%);
}

@media (width <= 600px) {
  :root {
    --font-size-heading-5: 10px;
    --font-size-heading-4: 14px;
    --font-size-heading-3: 18px;
    --font-size-heading-2: 22px;
    --font-size-heading-1: 28px;
  }
}