* {
  scrollbar-color: rgba(136 136 136 / 30%) transparent !important;
  scrollbar-width: thin !important;
}

body {
  font-family: var(--font-family-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: tabular-nums;
  scrollbar-width: thin !important;
}

html, body {
  height: 100%;
  width: 100%;

  @media screen and (width <= 600px) {
    height: auto;
  }
}

/* Styles for html elements */

ul {
  padding-left: 0;
}

/* Styles for Ant-design notification component */

.ant-notification-notice {
  padding: 20px 10px !important;
}

.ant-notification-notice-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  left: 10px;
  width: 20px;
  height: 20px;
}

.ant-notification-notice-with-icon svg {
  fill: #627fff !important;
}

.anticon.ant-notification-notice-icon-error svg {
  fill: #ff5300 !important;
}

.ant-notification .ant-notification-notice {
  border-radius: var(--border-radius-small);
  font-family: var(--font-family-regular);
  font-weight: var(--font-weight-medium);
}

.ant-notification-notice-error {
  background: var(--primary-oqio-red) !important;
}

.ant-notification-notice-success {
  background: var(--primary-oqio-green) !important;
}

.ant-notification-notice-info {
  background: var(--primary-oqio-blue) !important;
}


.ant-notification-notice-with-icon {
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
  flex-direction: column;
}

.ant-notification-notice-with-icon svg * {
  fill: transparent !important;
  stroke: var(--greyscale-white) !important;
}

.ant-notification-notice-with-icon svg path.dot {
  fill: var(--greyscale-white) !important;
  stroke: transparent !important;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close {
  top: 10px;
  right: 10px;
}

.ant-notification-notice-close svg path {
  stroke: var(--greyscale-white);
}

.ant-notification-notice-message {
  color: var(--greyscale-white) !important;
  flex: 1;
  margin-bottom: 0 !important;
  margin-inline-start: unset !important;
  line-height: 20px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  word-break: break-word;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-inline-start: 0 !important;
  font-size: var(--font-size-regular) !important;
  margin-top: 10px;
}

/* Styles for Ant-design list component */

.ant-list-items {
  font-weight: var(--font-weight-regular);
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
  border-radius: var(--border-radius-regular) !important;
}

.ant-menu-item.ant-menu-item-only-child {
  color: var(--greyscale-shade);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
}

.ant-menu-submenu.ant-menu-submenu-popup .ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item:hover,
.ant-menu-submenu.ant-menu-submenu-popup .ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item-active {
  background-color: var(--secondary-whisper) !important;
  color: var(--greyscale-blackish) !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--greyscale-dark-white) !important;
  color: var(--greyscale-blackish) !important;
  font-weight: var(--font-weight-medium) !important;
}

/* Styles for Ant-design select -> dropdown component
with the class no-checkmark there will be no checkmark */

.ant-select-dropdown {
  font-family: var(--font-family-regular);
  font-weight: var(--font-weight-medium);
  padding: 0 !important;
  border-radius: var(--border-radius-regular) !important;
  scrollbar-width: thin;
  max-width: 570px;
  width: auto;
}

.ant-select-item-option:not(.no-checkmark) {
  padding: 10px 20px 10px 40px !important;
}

.ant-select-item-option.no-checkmark {
  padding: 10px 14px !important;
}

.ant-select-item-option:not(:last-child) {
  border-bottom: 1px solid var(--greyscale-dark-white);
}

.ant-select-item-option-content,
.ant-select-item-empty {
  font-size: var(--font-size-regular) !important;
  line-height: var(--font-line-height-regular) !important;
  font-family: var(--font-family-regular) !important;
}

.ant-select-item-empty {
  padding: 10px 12px !important;
  color: var(--greyscale-concrete) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: initial !important;
  font-weight: var(--font-weight-medium) !important;
  background-color: initial !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled):not(.no-checkmark)::before {
  content: url('components/ui/Assets/check.svg');
  display: block;
  position: absolute;
  left: 14px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--greyscale-porcelain);
}

/* highlights characters in select input search result list */ 
.ant-select-item-option-content .highlight {
  font-weight: var(--font-weight-medium);
}

/* hides the svg icon in the select input search result list */
.ant-select-item-option-content span:not(.ant-btn-icon) svg {
  display: none;
}

.ant-select-item-option-content .non-highlight {
  color: var(--greyscale-shade);
}

.rc-virtual-list-scrollbar-thumb {
  background: rgb(136 136 136 / 30%) !important;
}

.rc-virtual-list-holder {
  max-height: 267px !important;
}

/* Styles for the worklist select -> dropdown component */

.worklist-template-options {
  max-width: 560px;
  padding: 10px 20px !important;
}

@media screen and (width <= 900px) {
  .worklist-template-options {
    max-width: 860px;
  }
}

/* in split screen intercom icon and chat need to be invisible because the worklist */

@media screen and (width <= 900px) {
  .intercom-lightweight-app,
  #intercom-container {
    display: none !important;
  }
}

/* Styles for Reporting year select -> dropdown component */

.reporting-year-select-option.ant-select-item-option {
  padding: 10px 20px !important;
}

.reporting-year-select-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled)::before {
  content: unset;
}

.reporting-year-select-option.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  content: unset;
}

/* Styles for the ant image preview component
make the toolbar transparent and adjust the mask depending on browser support */

.ant-image-preview-operations {
  background: none !important;
}

/* Styles for the ant modal and image preview mask */
@supports (backdrop-filter: blur(1px)) {
  .ant-image-preview-mask {
    backdrop-filter: blur(1px);
    background-color: rgb(17 17 17 / 30%) !important;
  }

  .ant-modal-mask {
    backdrop-filter: blur(1px);
    background-color: rgb(17 17 17 / 30%)
   }
}

@supports not (backdrop-filter: blur(1px)) {
  .ant-image-preview-mask {
    background-color: rgb(17 17 17 / 60%) !important;
  }

  .ant-modal-mask {
    background-color: rgb(17 17 17 / 60%)
   }
}

/* Styles for the Ant Modal in worklist component */
.ant-modal-wrap.worklist-message-reminder-modal-wrapper,
.ant-modal-wrap.worklist-claim-attachments-modal-wrapper {
  width: 600px;
  right: 0;
  left: auto;
}

@media screen and (width <= 900px) {
  .ant-modal-wrap.worklist-message-reminder-modal-wrapper,
  .ant-modal-wrap.worklist-claim-attachments-modal-wrapper {
    width: 100%;
    right: 0;
    left: 0;
  }
}

/* Styles for the Ant Modal scrollbar */
.ant-modal-wrap {
  scrollbar-width: thin !important;
}

/* Styles for the Ant SearchSelectInput component */
.ant-select-item.custom-option-selected {
  background-color: var(--greyscale-porcelain) !important;
  padding: 0 !important;
  position: sticky;
  top: 0;
  z-index: 15000 !important;
}


.ant-select-item.custom-option-label > .ant-select-item-option-content {
  color: var(--greyscale-blackish);
  cursor: default !important;
  font-size: var(--font-size-small) !important;
  font-weight: var(--font-weight-semi-bold) !important;
  line-height: var(--font-line-height-small) !important;
  text-transform: uppercase !important;
}

.ant-select-item.custom-option {
  background-color: var(--greyscale-white) !important;
  border-bottom: none;
  padding: 0 !important;
  position: sticky;
  top: 0;
  z-index: 15000 !important;
}

.ant-select-item.custom-option-label {
  border-bottom: none;
  color: var(--greyscale-blackish);
  cursor: default !important;
  font-size: var(--font-size-small) !important;
  font-weight: var(--font-weight-semi-bold) !important;
  line-height: var(--font-line-height-small) !important;
  padding: 10px 14px !important;
  text-transform: uppercase !important;
}